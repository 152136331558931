import React from 'react';
import styles from "./RightSection.module.css"; // Ensure to create and import the CSS for styling

function RightSection() {
  return (
    <div className={styles["right-section"]}>
      <h1 className={styles["title"]}>Quiztwiz</h1>
      <p className={styles["subtitle"]}>Welcome to Quiztwiz. Play a quiz and earn coins.</p>
      <p className={styles["description"]}>There's a quiz for everyone!</p>
      <div className={styles["image-container"]}>
        <img src="sidePoster.png" alt="Quiztwiz" />
      </div>
    </div>
  );
}

export default RightSection;
