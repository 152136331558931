import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from './app.module.css';
import RightSection from './component/RightSection/Index';
import LeftSection from './component/LeftSection';
import ProfilePage from './component/Profile/Profile';
import Home from './component/Home/Home';

function App() {
  return (
    <Router>
      <div className={styles["App"]}>
        <div className={styles["left-section"]}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/quiz/:id" element={<LeftSection />} />
          </Routes>
        </div>

        <div className={styles["right-section"]}>
          <RightSection />
        </div>
      </div>
    </Router>
  );
}

export default App;
