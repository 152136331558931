import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { storeToken, getToken } from "../../utils/AuthStorage";

function Login({ setIsLoggedIn, setShowLogin }) {
    const navigate = useNavigate();
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(true);
    const [isOtpSent, setIsOtpSent] = useState(false); 
    const [isOtpSentRegister, setIsOtpSentRegister] = useState(false); 
    const [login, setLogin] = useState({
      email: "",
      otp: "",
    });
    const [isFormValidLogin, setIsFormValidLogin] = useState(false);
    const [signUp, setSignUp] = useState({
      email: "",
      fullName: "",
      otp:""
    });
    const [isFormValidSignup, setIsFormValidSignup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const onClose = () => {
        setShowLoginPopup(false);
        setShowSignupPopup(false);
        setShowLogin(false);
      }
    
      const clearData = () => {
        setLogin({
            email: "",
            otp: "",
        })
        setSignUp({   
            email: "",
            fullName: "",
            otp:""
          });
      };

      const openLogin = () => {
        setShowLoginPopup(true);
        setShowSignupPopup(false);
        clearData();
    }
    const openSignup = () => {
        clearData();
        setShowSignupPopup(true);
        setShowLoginPopup(false);
    }
    
    const validateFormLogin = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(login.email) && login.otp) {
          setIsFormValidLogin(true);
        } else {
          setIsFormValidLogin(false);
        }
      };
    
      useEffect(() => {
        validateFormLogin();
      }, [login]);
    
      const handleChangeLogin = (e) => {
        const { name, value } = e.target;
        setLogin((prevLogin) => ({
          ...prevLogin,
          [name]: value,
        }));
      };

      const handleSendOtp = async () => {
        if(!login.email){
            toast.info("Please enter email");
            return;
        }
        setLoading(true);
        try {
            
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/user/signUp`, 
                { email: login.email },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
            setIsOtpSent(true);
            // console.log(response.data.message)
            toast.success(response?.data?.message || 'OTP sent successfully!');
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Error sending OTP. Please try again.');
            console.error(error);
        } finally{
            setLoading(false)
        }
    };

      const handleLoginSubmit = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/user/signIn`, 
                { 
                    email: login.email,
                    otp: login.otp,
                 },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
        
          const { data } = response.data;
        //   console.log("Login Successful", data);
        localStorage.setItem("quizuserid", data._id)
navigate(`/profile`);
          storeToken(data.token);
          setIsLoggedIn(true); 
          toast.success(response?.data?.message || "Login sucessfully!");
          setShowLogin(false);
          setShowLoginPopup(false); 
          clearData();
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred while signing in.");
    
                  }
      };
    
      const validateFormSignup = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (
          emailRegex.test(signUp.email) &&
          signUp.fullName &&
          signUp.otp         ) {
          setIsFormValidSignup(true);
        } else {
          setIsFormValidSignup(false);
        }
      };
    
      useEffect(() => {
        validateFormSignup();
      }, [signUp]);
    
      const handleChangeSignup = (e) => {
        const { name, value } = e.target;
        setSignUp((prevSignUp) => ({
          ...prevSignUp,
          [name]: value,
        }));
      };
    
    //   const handleSignupSubmit = () => {
    //     console.log("Signup Data", signUp);
    //     setShowSignupPopup(false);
    //   }; 
    const handleSendOtpRegister = async () => {
        if(!signUp.email){
            toast.info("Please enter email");
            return;
        }
        if(!signUp.fullName){
            toast.info("Please enter full name");
            return;
        }
        setLoadingRegister(true);
        try {
            
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/user/signUp`, 
                { 
                    email: signUp.email,
                    fullname: signUp.fullName 

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
            setIsOtpSentRegister(true);
            // navigate(`/profile`);
            // console.log(response.data.message)
            toast.success(response?.data?.message || 'OTP sent successfully!');
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Error sending OTP. Please try again.');
            console.error(error);
        } finally{
            setLoadingRegister(false);
        }
    };
    const handleRegisterSubmit = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/user/signIn`, 
                { 
                    email: signUp.email,
                    otp: signUp.otp,
                 },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
        
          const { data } = response.data;
        //   console.log("Login Successful", data);
    localStorage.setItem("quizuserid", data._id)
          storeToken(data.token);
          setIsLoggedIn(true); 
          toast.success(response?.data?.message || "Login sucessfully!");
          setShowLogin(false);
          setShowLoginPopup(false); 
          clearData();
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred while signing in.");
    
                  }
      };
  return (
    <div>
        <ToastContainer/>
              <Dialog open={showLoginPopup} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          type="email"
          fullWidth
          name="email"
          value={login.email}
          onChange={handleChangeLogin}
          required
          style={{ marginBottom: "15px", marginTop:"10px" }}
        />
        <Button 
                        onClick={handleSendOtp} 
                        variant="outlined" 
                        color="primary" 
                        style={{ marginBottom: "15px", width: "100%" }}>
                        {loading ? ("Sending..."):("Send OTP")}
                    </Button>
                    {isOtpSent && (
        <TextField
          label="Enter OTP"
          type="text"
          fullWidth
          name="otp"
          value={login.otp}
          onChange={handleChangeLogin}
          required
          style={{ marginBottom: "15px" }}
        />)}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Link href="#" 
          onClick={openSignup} 
          variant="body2" style={{ color: "#007bff" }}>
            Not registered? Sign up
          </Link>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={handleLoginSubmit}
          variant="contained"
          color="primary"
          disabled={!isFormValidLogin  || !isOtpSent}
        >
          Login
        </Button>
        <Button
         onClick={onClose}
          style={{ color: "#888" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

      {/* SignUp Dialog */}

    <Dialog open={showSignupPopup} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Sign Up</DialogTitle>
      <DialogContent>
        <TextField
          label="Full Name"
          fullWidth
          name="fullName"
          value={signUp.fullName}
          onChange={handleChangeSignup}
          required
          style={{ marginBottom: "15px", marginTop:"10px" }}
        />
        
        <TextField
          label="Email"
          type="email"
          fullWidth
          name="email"
          value={signUp.email}
          onChange={handleChangeSignup}
          required
          style={{ marginBottom: "15px" }}
        />
         <Button 
                        onClick={handleSendOtpRegister} 
                        variant="outlined" 
                        color="primary" 
                        style={{ marginBottom: "15px", width: "100%" }}>
                        {loadingRegister ? ("Sending..."):("Send OTP")}
                    </Button>
                    {isOtpSentRegister && (

        <TextField
          label="otp"
          type="password"
          fullWidth
          name="otp"
          value={signUp.otp}
          onChange={handleChangeSignup}
          required
          style={{ marginBottom: "15px" }}
        />)}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Link href="#" onClick={openLogin} variant="body2" style={{ color: "#007bff" }}>
            Already have an account? Login
          </Link>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={handleRegisterSubmit}
          variant="contained"
          color="primary"
          disabled={!isFormValidSignup   || !isOtpSentRegister}
        >
          Sign Up
        </Button>
        <Button onClick={onClose} style={{ color: "#888" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  )
}

export default Login