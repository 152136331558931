export const storeToken = (token) => {
    localStorage.setItem('tokenquiz', token);
  };

  export const getToken = () => {
    return localStorage.getItem('tokenquiz');
  };

  export const clearAuthData = () => {
    localStorage.removeItem('tokenquiz');
    localStorage.removeItem('quizuserid');
  };