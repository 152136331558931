import React, { useEffect, useState } from "react";
import styles from "./LeftSection.module.css";
import axios from "axios";
import Header from "../Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LeftSection() {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [incorrectAnswer, setIncorrectAnswer] = useState("");
  const [answers, setAnswers] = useState([]); 
  const [isSubmitted, setIsSubmitted] = useState(false);
console.log("answers",answers)
  useEffect(() => {
    fetchQuestions();
  }, [id]);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz/${id}`, {
        headers: {
          "Accept": "application/json",
        },
      });
      const quizData = response.data.data.questions;
      setQuestions(quizData); 
    } catch (err) {
      console.error("Error fetching quiz data:", err);
    }
  };

  const checkAnswer = (sequence, option, index) => {
    if (option.isCorrect) {
      setCorrectAnswer(sequence);
    } else {
      setIncorrectAnswer(sequence);
    }

    
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentQuestionIndex] = index; 
      return newAnswers;
    });

    // Move to the next question
    setTimeout(() => {
      setCorrectAnswer("");
      setIncorrectAnswer("");
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);  
    }, 1000); 
  };

 
  const currentQuestion = questions[currentQuestionIndex];

  useEffect(() => {
    const token = localStorage.getItem("tokenquiz");
    if (!token) {
      navigate("/"); 
    }
    
  }, [navigate]);
  const handleSubmit = async () => {
    const userId = localStorage.getItem("quizuserid");
    if (!userId) {
      alert("User not logged in!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/quiz/attempt`,
        {
          quiz: id,
          user: userId,
          option: answers,  
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Quiz submitted successfully", response.data);
      toast.success(response?.data?.message || "Submitted Successfully")
      setIsSubmitted(true);
      navigate(`/`);
    } catch (err) {
      console.error("Error submitting quiz:", err);
      toast.error(err?.response?.data?.message || "Submitted Successfully")

    }
  };

 
  const showSubmitButton = currentQuestionIndex === questions.length;

  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <ToastContainer/>
      <Header />
      <div className={styles["quiz-section"]}>
        <p className={styles["quiz-sub-line"]}>
          Answer a few questions and win 10 Coins free!
        </p>
        <h3 style={{ color: "#FFF" }}>
          {currentQuestion?.text } 
        </h3>
        <div className={styles["options"]}>
          {currentQuestion?.choices?.map((choice, index) => (
            <button
              key={index}
              onClick={() => checkAnswer(choice._id, choice, index)}
              style={{
                background:
                  correctAnswer === choice._id
                    ? "green"
                    : incorrectAnswer === choice._id
                    ? "red"
                    : "",
              }}
            >
              {choice.text}
            </button>
          ))}
        </div>
        
        {showSubmitButton && !isSubmitted && (
           <Button
           onClick={handleSubmit}
           variant="contained"
           color="primary"
         >
           Submit Quiz
         </Button>
        )}
         <div className={styles["info"]}>
        <h4>Play Quiz and Win Coins!</h4>
        <ul>
          <li>
            Play Quizzes in 25+ categories like GK, Sports, Bollywood, Business,
            Cricket & more!
          </li>
          <li>Compete with lakhs of other players!</li>
          <li>Win coins for every game.</li>
          <li>Trusted by millions of quiz enthusiasts like YOU!</li>
        </ul>
      </div>
      <div className={styles["fun-facts"]}>
        <h4>Fun Facts</h4>
        <p>
          The insurance industry is one of the largest industries in the United
          States, with over $1.5 trillion in annual premiums. The word
          "insurance" comes from the French word "assurer", which means "to make
          sure". The first insurance company in the United States was founded in
          Charleston, South Carolina, in 1735. The insurance industry employs
          over 2 million people in the United States...
        </p>
      </div>
      </div>
    </div>
  );
}

export default LeftSection;
