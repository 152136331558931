import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import Header from "../Header/Header";
import { getToken } from "../../utils/AuthStorage";
import Login from "../Login/Login";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());
  const [showLogin, setShowLogin] = useState(false);

  const handleLastImageClick = (id) => {
    const token = getToken();
    if (token) {
      navigate(`/quiz/${id}`);
    } else {
      setShowLogin(prevState=>!prevState)
    }
  };

  const handleData = async () => {
    try {
      // const token = localStorage.getItem("tokenquiz");
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz/getAll`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Quiz data fetched Successfully", response.data.data);
      setQuizData(response.data.data);
    } catch (error) {
      console.error(
        error?.response?.data?.message || "Failed fetching quiz detail"
      );
    } finally {
      setLoading(false);
    }
  };
  const handleDataLogin = async () => {
    try {
      const userid = localStorage.getItem("quizuserid");
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz/getAll?user=${userid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Quiz data fetched Successfully", response.data.data);
      setQuizData(response.data.data);
    } catch (error) {
      console.error(
        error?.response?.data?.message || "Failed fetching quiz detail"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const quizUserId = localStorage.getItem("quizuserid");
    if (quizUserId) {
      handleDataLogin();
    } else {
      handleData();
    }
  }, []);

  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <ToastContainer />
      <Header />
      
      {loading ? (
        <div style={{color:"#fff", fontWeight:"600", fontSize:"20px"}} >
          Loading...
        </div>
      ) : (
        <div className={styles.cardContainer}>
          {quizData.length === 0 ? (
            <div className={styles.noDataMessage} style={{color:"#fff"}}>
              No quizzes available at the moment.
            </div>
          ) : (
            quizData?.map((data, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.cardImageContainer}>
                  <img
                    src={data?.category?.image ? data?.category?.image : "./first.png"}
                    alt="Starting Image"
                    className={styles.cardImage}
                  />
                </div>
                <div className={styles.cardDetails}>
                  <p className={styles.cardText}>{data?.category?.name}</p>
                  <h3 className={styles.cardHeading}>{data?.name}</h3>
                  {/* <p className={styles.cardFee}>Entry Fee: -500</p> */}
                </div>
                <div
                  className={styles.cardEndImageContainer}
                  onClick={()=>handleLastImageClick(data?._id)}
                >
                  <img
                    src="./last.svg"
                    alt="Round Image"
                    className={styles.cardEndImage}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      )}
            {showLogin && <Login setIsLoggedIn={setIsLoggedIn} setShowLogin={setShowLogin} />} 
    </div>
  );
};

export default Home;
